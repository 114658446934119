import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
export var useBanner = function useBanner() {
  var route = useRoute();
  var forbidenPaths = ["learning-subscription", "learning-article-slug", "learning-charity-support-afu", "learning-trial", "learning-sale-slug", "learning-trial", "learning-donorua", "article-slug", "news-slug", "registration", "login", "forgotpassword", "about", "offer", "terms", "confidential", "cookie", "my-settings", "refresh"];
  var isSubPage = function isSubPage() {
    if (!route.name) return true;
    return forbidenPaths.some(function (s) {
      return route.name.indexOf(s) >= 0;
    });
  };

  /**
   * Check if current route points to a course page/landing page
   *
   * @param {string|string[]} slug course slugs to check
   * @returns {boolean}
   */
  var matchCourseBySlug = function matchCourseBySlug(slug) {
    var _slug;
    slug = Array.isArray(slug) ? slug : [slug];
    if (!((_slug = slug) !== null && _slug !== void 0 && _slug.length)) return false;
    var name = route.name;
    if (!name) return false;
    return [/^learning-course-course/, [/^learning-course-slug/, 'slug']].some(function (params) {
      var regex = Array.isArray(params) ? params[0] : params;
      var paramKey = Array.isArray(params) ? params[1] || 'course' : 'course';
      if (!regex.test(name)) return false;
      return paramKey in (route === null || route === void 0 ? void 0 : route.params) && slug.includes(route.params[paramKey]);
    });
  };
  var isPartnerCourse = function isPartnerCourse() {
    return matchCourseBySlug(['creativity-beyond-borders']);
  };
  var isCuratedCourse = function isCuratedCourse() {
    return matchCourseBySlug(['ux-ui-advanced-curated', 'smm-strategy-design-content']);
  };
  return {
    isSubPage: isSubPage,
    isPartnerCourse: isPartnerCourse,
    isCuratedCourse: isCuratedCourse
  };
};