import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export var usePickAwardLevelLocale = function usePickAwardLevelLocale(item) {
  var _item$award;
  if (!item) {
    console.error('No item in usePickAwardLevelLocale');
    return '';
  }
  var _useNuxtApp = useNuxtApp(),
    $i18n = _useNuxtApp.$i18n;
  if (item !== null && item !== void 0 && (_item$award = item.award) !== null && _item$award !== void 0 && _item$award.levels) {
    var level = item.award.levels.find(function (o) {
      return o.id == item.level;
    });
    if (level) {
      if (level[$i18n.locale]) return level[$i18n.locale];
      if (level.en) return level.en;
      if (level.uk) return level.uk;
      return level.id;
    }
  }
  return item.level || '';
};