<template>
    <section class="page-container d-flex py-4">
        <div class="row align-items-center" v-if="error.statusCode === 404">
            <div class="col-md-6 ta-center ta-md-right order-md-2 mb-4 mb-md-0 transition-up-2">
                <img
                    src="/images/creative-practice/404.png"
                    alt=""
                    width="328"
                    height="224"
                    class="image scheme-light-only"
                >
                <img
                    src="/images/creative-practice/404-dark.png"
                    alt=""
                    width="328"
                    height="224"
                    class="image scheme-dark-only"
                >
            </div>

            <div class="col-md-6 transition-up-1 ta-center ta-md-left">
                <h1 class="fw-900 h2 tw-balance">{{ $t('page.404.title') }}</h1>
                <p>{{ $t('page.404.p1') }}</p>

                <p v-if="!$auth.user">
                    {{ $t('page.404.p2') }}
                    <panel-button
                        name="login"
                        class="b-link fw-600"
                    >{{ $t('page.404.login') }}</panel-button>.
                </p>
            </div>

        </div>

        <div class="row align-items-center" v-else>
            <div class="col-md-5 ta-center ta-md-right order-md-2 mb-4 mb-md-0 transition-up-2">
                <img
                    src="/images/creative-practice/500.png"
                    alt=""
                    width="328"
                    height="224"
                    class="image scheme-light-only"
                >
                <img
                    src="/images/creative-practice/500-dark.png"
                    alt=""
                    width="328"
                    height="224"
                    class="image scheme-dark-only"
                >
            </div>

            <div class="col-md-7 transition-up-1 ta-center ta-md-left">
                <img src="/images/creative-practice/error-logos.svg"  width="287" height="36" alt="" class="scheme-light-only">
                <img src="/images/creative-practice/error-logos-dark.svg"  width="287" height="36" alt="" class="scheme-dark-only">

                <h1 class="fw-900 h2 mt-5 tw-balance">{{ $t('page.500.title') }}</h1>
                <p>{{ $t('page.500.p1') }}</p>
                <p>{{ $t('page.500.p2') }}</p>
            </div>

        </div>
    </section>
</template>

<script>
import PanelButton from '~/components/panels/PanelButton.vue';
export default {
    // layout: 'default',

    components: {
        PanelButton
    },

    props: ['error'],

    async asyncData ({ $appStore }) {
        $appStore.$patch({activeApp: null});
        return true;
    },
}
</script>

<style lang="scss" scoped>
    .image {
        width: 100%;
        max-width: 228px;
        height: auto;

        @media screen and (min-width: 768px) {
            max-width: 328px;
        }
    }

    .page-container {
        min-height: calc(100dvh - 100px);
    }
</style>
